import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedin,
  faXTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";

const ProjectDetail = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `https://www.leads4contact.com/backendnode/project/${id}`
        );
        if (response.data) {
          setProject(response.data);
        } else {
          setError("Project not found");
        }
      } catch (error) {
        setError("Failed to load project details");
        console.error("Error fetching project details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProject();
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#3498db" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen text-red-600">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <section className="bg-[#f0f4f8] py-12 mt-12">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-[#17315c] text-center">
            {project.title}
          </h2>
        </div>
      </section>

      {/* Project Details and Client Review Section */}
      <section className="container mx-auto grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-12 px-6 items-start">
        {/* Project Details (Fixed Width) */}
        <div className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-semibold text-[#18335d] mb-6">
            Project Details
          </h2>
          <p
            className="text-lg text-[#374151] mb-6"
            dangerouslySetInnerHTML={{
              __html: project.details || "No project details available.",
            }}
          />
        </div>

        {/* Client Review Section (Auto Height) */}

        <div className="bg-white shadow-lg rounded-lg p-8">
          <p className="text-lg text-[#18335d] font-bold">
            Status:{" "}
            <span
              className={`font-semibold ${
                project.status === "Continue"
                  ? "text-yellow-400"
                  : "text-green-400"
              }`}
            >
              {project.status === "Continue" ? "Ongoing" : "Completed"}
            </span>
          </p>
          <br />
          <h2 className="text-xl font-semibold text-[#18335d] ">
          Tools Used
          </h2>
          <p className="text-lg mb-6"> {project.tools}</p>
          <h2 className="text-3xl font-semibold text-[#18335d] mb-4">
            Client Review
          </h2>
          <p className="italic text-[#374151] border-l-4 border-yellow-500 pl-6 mb-4">
            {project.clientReview || "No review available."}
          </p>
          <p className="text-right text-[#6b7280]">
            - {project.clientName || "Anonymous"}
          </p>

          {/* End Date and Ranking */}
          {project.status !== "Continue" && (
            <>
              <div className="text-lg text-[#374151] mt-4">
                <strong className="text-[#18335d]">End Date:</strong>{" "}
                {new Date(project.endDate).toLocaleDateString() || "N/A"}
              </div>

              <div className="text-lg text-[#374151] mt-2">
                <strong className="text-[#18335d]">Project Ranking:</strong>{" "}
                {Array.from({ length: project.projectRanking || 0 }, (_, i) => (
                  <span key={i} className="text-yellow-500">
                    ⭐
                  </span>
                ))}
              </div>
            </>
          )}
          {/* Share Section - Always Visible */}
          <div className="bg-[#eef2f6] py-4 px-4 mt-4 flex justify-center gap-4">
            <div className="container mx-auto text-center">
              <h2 className="text-xl font-semibold text-[#18335d] mb-4">
                Share This Project
              </h2>
              <div className="flex justify-center gap-6">
                {/* Twitter/X Share - Direct Share */}
                <a
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    project.title + " - " + window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#1DA1F2] hover:text-[#000000] transition duration-300"
                >
                  <FontAwesomeIcon icon={faXTwitter} size="2x" />
                </a>

                {/* Facebook - Opens "Create Post" with link pre-filled */}
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#4267B2] hover:text-[#3b5998] transition duration-300"
                >
                  <FontAwesomeIcon icon={faFacebookF} size="2x" />
                </a>

                {/* LinkedIn - Opens "Create Post" with link pre-filled */}
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#0077b5] hover:text-[#006097] transition duration-300"
                >
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>

                {/* WhatsApp - Direct Share */}
                <a
                  href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    project.title + " - " + window.location.href
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#25D366] hover:text-[#128C7E] transition duration-300"
                >
                  <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Navigation Button */}
      <section className="py-12">
        <div className="container mx-auto text-center">
          <button
            onClick={() => navigate("/projects")}
            className="bg-[#18335d] hover:bg-[#004d99] text-white font-bold py-2 px-8 rounded-md mb-4 md:ml-6"
          >
            View More Projects
          </button>
        </div>
      </section>
    </div>
  );
};

export default ProjectDetail;
