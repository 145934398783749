import React from "react";
import { useNavigate } from "react-router-dom";

const AdminHome = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="grid grid-cols-2 gap-10">
        {/* Project Detail Card */}
        <div
          className="relative bg-white shadow-lg rounded-lg overflow-hidden w-80 h-96 cursor-pointer hover:scale-105 transform transition-all"
          onClick={() => navigate("/admin/view-projects")}
        >
          <img
            src="https://via.placeholder.com/400x300.png?text=Projects"
            alt="Project Detail"
            className="w-full h-2/3 object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 hover:bg-opacity-50 transition-opacity"></div>
          <div className="absolute bottom-0 bg-blue-500 bg-opacity-90 w-full py-4 text-center">
            <h2 className="text-white text-xl font-semibold">Project Detail</h2>
          </div>
        </div>

        {/* Job/Career Detail Card */}
        <div
          className="relative bg-white shadow-lg rounded-lg overflow-hidden w-80 h-96 cursor-pointer hover:scale-105 transform transition-all"
          onClick={() => navigate("/addjob")}
        >
          <img
            src="https://via.placeholder.com/400x300.png?text=Jobs"
            alt="Job/Career Detail"
            className="w-full h-2/3 object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 hover:bg-opacity-50 transition-opacity"></div>
          <div className="absolute bottom-0 bg-green-500 bg-opacity-90 w-full py-4 text-center">
            <h2 className="text-white text-xl font-semibold">Job/Career Detail</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
