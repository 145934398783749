import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Import Email Icon
import img1 from './../images/mrabdulrehman.png';
import img2 from './../images/mrabdullah.png';
import img3 from './../images/mrina.jpeg';
import img4 from './../images/Female-Avatar.png';
import img5 from './../images/talha-demo-img.png';
import { faUpwork } from '@fortawesome/free-brands-svg-icons'; // Import Upwork Icon

const teamMembers = [
  {
    name: 'Abdul Rehman',
    title: 'Lead-Generation Expert',
    image: img1,
    linkedin: '#', // Replace with actual LinkedIn link
    facebook: '#', // Replace with actual Facebook link
    upwork: 'https://www.upwork.com/freelancers/~01210237de26a269bf/', // Replace with actual Upwork link
    email: 'arehman@leads4contact.com' // Add actual email address here
  },
  {
    name: 'Muhammad Abdullah',
    title: 'Lead-Generation Specialist',
    image: img2,
    linkedin: 'https://www.linkedin.com/in/muhammad-abdullah-462378111/', // Replace with actual LinkedIn link
    facebook: '#', // Replace with actual Facebook link
    upwork: 'https://www.upwork.com/freelancers/muhammadabdullah35', // Replace with actual Upwork link
    email: 'm.abdullah.btme@gmail.com' // Add actual email address here
  },
  {
    name: 'Muhammad Inam',
    title: 'Lead-Generation Expert',
    image: img3,
    linkedin: 'https://www.linkedin.com/in/muhammad-inam-021852166/', // Replace with actual LinkedIn link
    facebook: '#', // Replace with actual Facebook link
    upwork: 'https://www.upwork.com/freelancers/~015b361864365eb9f9/', // Replace with actual Upwork link
    email: 'muhammadinam791@gmail.com' // Add actual email address here
  },
  {
    name: 'Sumayya Tayyaba',
    title: 'Lead Enrichment Expert',
    image: img4,
    linkedin: '#',
    facebook: '#', 
    upwork: '#', 
    email: '#' 
  },
  {
    name: 'Talha Tariq',
    title: 'Web Developer & Scrapper',
    image: img5,
    linkedin: 'https://www.linkedin.com/in/talha-tariq-937067219/',
    facebook: 'https://www.facebook.com/talha.tariq.785/',
    upwork: 'https://www.upwork.com/freelancers/~0189f3d8fcbe49b5f1',
    email: 'talhatariq.swe@gmail.com'
  }
];

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="bg-[#f0f4f8] py-12 mt-12">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Our Team</h2>
          
          <div className={`grid ${teamMembers.length < 3 ? 'grid-cols-1 sm:grid-cols-2' : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'} gap-10`}>
            {teamMembers.map((member, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6 text-center transform hover:scale-105 transition-transform duration-300">
                <img 
                  src={member.image} 
                  alt={member.name} 
                  className="w-60 h-60 mx-auto rounded-lg object-cover mb-4 border-4 border-[#17315c]"  // Rounded square effect
                />
                <h3 className="text-xl font-semibold text-[#17315c]">{member.name}</h3>
                <p className="text-[#777] mb-4">{member.title}</p>
                <div className="flex justify-center space-x-6">
                  <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="text-[#0077B5] hover:text-[#005582] transition-colors duration-200">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  </a>
                  <a href={member.upwork} target="_blank" rel="noopener noreferrer" className="text-[#5C7F2D] hover:text-[#4C6B24] transition-colors duration-200">
                    <FontAwesomeIcon icon={faUpwork} size="2x" />
                  </a>
                  <a href={`mailto:${member.email}`} className="text-[#d44638] hover:text-[#a7352c] transition-colors duration-200">
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;