import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const projects = [
  {
    title: "Fortune 1000 VPs and Directors Mining",
    tools: ["Leads Generation", "Spreadsheet", "Data Mining"],
    details:
      "The project utilized advanced data mining techniques to extract critical contact details from various sources. A comprehensive database was created, allowing clients to directly engage with top executives in target industries.",
  },
  {
    title: "Finding Property Owner and Skip Tracing",
    tools: ["Podio", "Web Research", "Data Entry", "Excel"],
    details:
      "The project used Podio for property management integration and data entry, while web research and skip tracing tools were employed to find accurate contact details. The result was a streamlined process for clients to contact property owners efficiently.",
  },
  {
    title: "Lead Generation List Building",
    tools: ["LinkedIn", "Data.com", "Excel"],
    details:
      "Through LinkedIn and Data.com, targeted leads were identified, filtered based on criteria, and then compiled into Excel for easy use by the client's sales team. This approach helped optimize outreach and improve lead conversion rates.",
  },
  {
    title: "Help with Web Research, Lead Generation, Data Entry, and more!",
    tools: ["LinkedIn", "Web Research", "Salesforce"],
    details:
      "By using LinkedIn and various web research tools, this project generated targeted leads for clients. Data was then entered into Salesforce for tracking and follow-up, allowing clients to improve customer engagement.",
  },
  {
    title: "Lawn Care Lead Scraping",
    tools: ["Yellowpages", "Yelp", "B2B", "Web Research"],
    details:
      "Using web scraping techniques, relevant leads from directories like Yellowpages and Yelp were gathered. The project enhanced lead generation efforts for lawn care services by focusing on local B2B opportunities.",
  },
  {
    title: "Researching B2B/B2C Contacts",
    tools: ["Leads Generation", "Salesforce Management"],
    details:
      "Through extensive research, this project targeted relevant B2B and B2C contacts across industries. The data was seamlessly integrated into Salesforce, allowing for effective tracking and follow-up by sales teams.",
  },
  {
    title: "Finding contact info for cannabis businesses and investors in the USA",
    tools: ["LinkedIn", "Web Research", "Spreadsheet"],
    details:
      "With LinkedIn as the primary tool for lead identification, this project gathered information about cannabis businesses and investors. The data was then organized in a spreadsheet for easy access and outreach by clients.",
  },
  {
    title: "Search companies from Zoominfo and put contact information in spreadsheet",
    tools: ["Zoominfo", "Data Entry", "Web Research"],
    details:
      "Using Zoominfo’s database, this project focused on identifying key companies and collecting contact information. The resulting data was entered into a spreadsheet, making it easier for clients to follow up with leads.",
  },
];

const iconMapping = {
  "Leads Generation": "📊",
  Spreadsheet: "📋",
  "Data Mining": "🔍",
  Podio: "📝",
  "Web Research": "🌐",
  "Data Entry": "⌨️",
  Excel: "📈",
  "Salesforce Management": "⚙️",
  "Salesforce": "⚙️",
  LinkedIn: "🔗",
  "Yellowpages": "📞",
  Yelp: "⭐",
  "B2B": "🏢",
  "Data.com": "💼",
  Zoominfo: "📊",
};

const Projects = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out", once: true });
  }, []);

  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4 pt-12 max-w-full overflow-hidden">
        <h2
          className="text-4xl font-bold text-[#17315c] text-center mb-8"
          data-aos="fade-down"
        >
          Our Projects
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-md p-6 space-y-6 transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
              data-aos={index % 2 === 0 ? "fade-up" : "fade-left"}
              data-aos-delay={`${index * 100}`}
            >
              {/* Project Title */}
              <h3 className="text-2xl font-semibold text-[#1a202c] mb-4">
                {project.title}
              </h3>

              {/* Detailed Description */}
              <p className="text-sm text-gray-600 leading-relaxed mb-6">
                {project.details}
              </p>

              {/* Tools List */}
              <ul className="text-sm text-gray-600 space-y-2">
                {project.tools.map((tool, toolIndex) => (
                  <li key={toolIndex} className="flex items-center">
                    <span className="mr-3 text-lg">{iconMapping[tool]}</span>
                    <span>{tool}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
