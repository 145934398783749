import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UpdateProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    tools: "",
    details: "",
    active: true,
    status: "",
    projectRanking: 0,
    share: false,
    endDate: "1900-01-01",
    clientReview: "",
    clientName: "",
    sortOrder: 0,
    imageUrl: "",
  });

  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null); // State for image
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const toolsOptions = [
    "Zoominfo", "Data Entry", "Web Research", "LinkedIn", "Spreadsheet",
    "Leads Generation", "Salesforce Management", "Yellowpages", "Yelp", "B2B",
    "Data.com", "Excel", "Podio", "Data Mining",
  ];

  useEffect(() => {
    fetchProject();
  }, [id]);

  const fetchProject = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://www.leads4contact.com/backendnode/project/${id}`);
      const {
        title,
        tools,
        details,
        active,
        status,
        projectRanking,
        share,
        endDate,
        clientReview,
        clientName,
        sortOrder,
        imageUrl,
      } = response.data;

      setFormData({
        title,
        tools,
        details,
        active,
        status,
        projectRanking,
        share,
        endDate: endDate.split("T")[0],
        clientReview,
        clientName,
        sortOrder,
        imageUrl,
      });
      setDescription(details);
    } catch (error) {
      console.error("Error fetching project details:", error);
      setError("Failed to fetch project details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: fieldValue });
  };

  const handleToolSelection = (tool) => {
    const toolsArray = formData.tools ? formData.tools.split(",") : [];
    if (toolsArray.includes(tool)) {
      const updatedTools = toolsArray.filter((t) => t.trim() !== tool.trim());
      setFormData({ ...formData, tools: updatedTools.join(",") });
    } else {
      setFormData({ ...formData, tools: [...toolsArray, tool].join(",") });
    }
  };

  const uploadImageToCloudinary = async () => {
    if (!image) return null;

    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "your_upload_preset"); // Replace with your Cloudinary preset

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/your_cloud_name/image/upload", // Replace with your Cloudinary URL
        formData
      );
      return response.data.secure_url;
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Failed to upload image. Please try again.");
      return null;
    }
  };
  const handleToolInput = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, tools: value });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const validateForm = () => {
    if (!formData.title.trim()) {
      setError("Title is required.");
      return false;
    }
    if (formData.status === "Ended" && formData.endDate === "1900-01-01") {
      setError("End Date is required when status is 'Ended'.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    setLoading(true);
    try {
      let imageUrl = formData.imageUrl;

      if (image) {
        const uploadedImageUrl = await uploadImageToCloudinary();
        if (uploadedImageUrl) {
          imageUrl = uploadedImageUrl;
        }
      }

      const updatedData = {
        ...formData,
        imageUrl,
        details: description,
      };

      await axios.put(
        `https://www.leads4contact.com/backendnode/project/${id}`,
        updatedData
      );
      alert("Project updated successfully!");
      navigate("/admin/view-projects");
    } catch (error) {
      console.error("Error updating project:", error);
      setError("Failed to update the project. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8 mt-10 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Update Project</h2>
      {loading && <div className="text-center mb-4">Loading...</div>}
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="max-w-full bg-white p-6 shadow-md rounded">
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Title</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            required
          />
        </div>

        {/* Tools Section */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Tools</label>
          <div className="grid grid-cols-3 gap-4 mb-2">
            {toolsOptions.map((tool) => (
              <div key={tool} className="flex items-center">
                <input
                  type="checkbox"
                  id={tool}
                  checked={formData.tools.split(",").includes(tool)}
                  onChange={() => handleToolSelection(tool)}
                  className="mr-2"
                />
                <label htmlFor={tool} className="text-gray-700">{tool}</label>
              </div>
            ))}
          </div>
          <input
            type="text"
            name="tools"
            value={formData.tools}
            onChange={handleToolInput}
            className="w-full border rounded px-3 py-2"
            placeholder="Add tools, separated by commas"
          />
        </div>

        {/* Description */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Description</label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            className="bg-white"
          />
        </div>

        {/* Other Fields */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          >
            <option value="Continue">Continue</option>
            <option value="Ended">Ended</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">End Date</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            disabled={formData.status !== "Ended"}
            required={formData.status === "Ended"}
          />
        </div>

        {/* Other Fields */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Project Ranking</label>
          <input
            type="number"
            name="projectRanking"
            value={formData.projectRanking}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            min="1" // Minimum value
            max="5" // Maximum value
            required // Ensures the field is not empty
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Share</label>
          <input
            type="checkbox"
            name="share"
            checked={formData.share}
            onChange={handleChange}
            className="mr-2"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Client Review</label>
          <textarea
            name="clientReview"
            value={formData.clientReview}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            rows="2"
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Client Name</label>
          <input
            type="text"
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Sort Order</label>
          <input
            type="number"
            name="sortOrder"
            value={formData.sortOrder}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
             {/* Image Upload */}
             <div className="mb-4">
          <label className="block text-gray-700 mb-2">Upload Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full border rounded px-3 py-2"
          />
          {formData.imageUrl && !image && (
            <div className="mt-2">
              <img src={formData.imageUrl} alt="Project" className="w-32 h-32 rounded" />
            </div>
          )}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Project"}
        </button>
      </form>
    </div>
  );
};

export default UpdateProject;