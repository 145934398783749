import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Team from './pages/Team';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import Services from './pages/Services';
import Career from './pages/Career';
import ApplyNow from './pages/ApplyNow';
import Projects from './pages/Projects';
import SignIn from './pages/SignIn';
import ProtectedRoute from './components/ProtectedRoute';
import AdminJobForm from './pages/AdminJobForm'
import EditJob from './components/adminJobForm/EditJob'
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import FullScreenSection from './components/home/FullScreenSection';
import ScrollToTop from './components/ScrollToTop';
import JobApplicant from './pages/JobApplicant';
import AddProject from './components/AdminProjectPages/AddProject';
import ViewProjects from './components/AdminProjectPages/ViewProjects';
import UpdateProject from './components/AdminProjectPages/UpdateProject';
import AdminHome from './pages/AdminHome';
import ProjectPage from './components/project/ProjectPage'
import ProjectDetail from './components/project/ProjectDetail';



function App() {
  return (
    //  basename="/"
    <Router > 
      <ScrollToTop/>
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contact" element={<Contact />} />
            <Route path='/services' element={<Services/>}/>
            <Route path='/career' element={<Career/>}/>
            <Route path="/applynow/:jobId" element={<ApplyNow />} />
            <Route path='/project' element={<Projects/>}/>
            <Route path='/projects' element={<ProjectPage/>}/>
            <Route path='/signin' element={<SignIn/>}/>
            <Route path="/adminHome" element={<ProtectedRoute>  <AdminHome /></ProtectedRoute>} />
            <Route path="/addjob" element={<ProtectedRoute>  <AdminJobForm /></ProtectedRoute>} />
            <Route path="/jobApplicant/:jobId" element={<ProtectedRoute>  <JobApplicant /></ProtectedRoute>} />
            <Route path="/editjob/:jobId" element={<ProtectedRoute>  <EditJob/></ProtectedRoute>} />
            <Route path="/editjob" element={<ProtectedRoute>  <EditJob/></ProtectedRoute>} />
            <Route path="/project/:id" element={  <ProjectDetail />} />
            
            <Route path="/admin/add-project" element={<ProtectedRoute><AddProject /></ProtectedRoute>} />
            <Route path="/admin/view-projects" element={<ProtectedRoute><ViewProjects /></ProtectedRoute>} />
            <Route path="/admin/update-project/:id" element={<ProtectedRoute><UpdateProject /></ProtectedRoute>} />


            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/full" element={<FullScreenSection />} />
          </Routes>
          <Footer/>
    </Router>
  );
}

export default App;
