import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import ClipLoader from "react-spinners/ClipLoader";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleProjects, setVisibleProjects] = useState(9);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://www.leads4contact.com/backendnode/project/"
        );
        // Sort projects by the sortOrder property
        const sortedProjects = response.data.sort((a, b) => a.sortOrder - b.sortOrder);
        setProjects(sortedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out", once: true });
  }, []);

  const handleLoadMore = () => {
    setVisibleProjects((prev) => prev + 9);
  };

  return (
    <section className="py-12 bg-gray-100">
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <ClipLoader color="#3498db" loading={loading} size={50} />
        </div>
      ) : (
        <div className="container mx-auto px-4 pt-12 max-w-full overflow-hidden">
          <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8" data-aos="fade-down">
            Our Projects
          </h2>
          {projects.length === 0 ? (
            <div className="flex items-center justify-center h-64">
              <h2 className="text-4xl font-bold text-[#17315c] text-center" data-aos="fade-down">
                No Projects Found!
              </h2>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {projects.slice(0, visibleProjects).map((project, index) => (
                  <div
                    key={project._id}
                    className="bg-white rounded-lg shadow-md overflow-hidden transform transition-transform hover:scale-105 cursor-pointer"
                    onClick={() => navigate(`/project/${project._id}`)}
                    data-aos="fade-up"
                    data-aos-delay={`${index * 100}`}
                  >
                    <div className="relative w-full h-56">
                      <img
                        src={project.imageUrl || "https://via.placeholder.com/150"}
                        alt={project.title}
                        className="absolute inset-0 w-full h-full object-contain"
                      />
                    </div>
                    <div className="p-4">
                      <h2 className="text-lg font-semibold text-gray-800 truncate">
                        {project.title}
                      </h2>
                    </div>
                  </div>
                ))}
              </div>
              {visibleProjects < projects.length && (
                <div className="flex justify-center mt-8">
                  <button
                    onClick={handleLoadMore}
                    className="bg-[#18335d] hover:bg-[#004d99] text-white font-bold py-2 px-8 rounded-md mb-4 md:ml-6"
                  >
                    Load More Projects
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default Projects;
