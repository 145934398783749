import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ViewProjects = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  const fetchProjects = async () => {
    try {
      const response = await axios.get("https://www.leads4contact.com/backendnode/project");
      console.log(response.data)
      setProjects(response.data);
    } catch (error) {
      alert("Error fetching projects!");
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div className="max-w-6xl mx-auto mt-20 mb-20">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">All Projects</h2>
        <button
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
          onClick={() => navigate("/admin/add-project")}
        >
          Add Project
        </button>
      </div>
      <table className="w-full border-collapse border border-gray-200 bg-white">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-4 py-2">#</th>
            <th className="border px-4 py-2">Title</th>
            <th className="border px-4 py-2">Tools</th>
            <th className="border px-4 py-2">Status</th>
            <th className="border px-4 py-2">End Date</th>
            <th className="border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, index) => (
            <tr key={project._id}>
              <td className="border px-4 py-2">{index + 1}</td>
              <td className="border px-4 py-2">{project.title}</td>
              <td className="border px-4 py-2">{project.tools}</td>
              <td className="border px-4 py-2">{project.active ? "Active" : "Inactive"}</td>
              <td className="border px-4 py-2">
                {new Date(project.endDate).toLocaleDateString()}
              </td>
              <td className="border px-4 py-2">
                <button
                  className="bg-green-500 text-white px-4 py-1 rounded mr-2 hover:bg-green-600"
                  onClick={() => navigate(`/admin/update-project/${project._id}`)}
                >
                  Update
                </button>
                <button
                  className="bg-red-500 text-white mt-2 px-4 py-1 rounded hover:bg-red-600"
                  onClick={async () => {
                    try {
                      await axios.delete(`https://www.leads4contact.com/backendnode/project/${project._id}`);
                      alert("Project deleted successfully!");
                      fetchProjects();
                    } catch (error) {
                      alert("Error deleting project!");
                    }
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewProjects;
