import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

const AddProject = () => {
  const [formData, setFormData] = useState({
    title: "",
    tools: "",
    active: true,
    status: "Continue",
    projectRanking: 0,
    share: false,
    endDate: "1900-01-01",
    clientReview: "",
    clientName: "",
    sortOrder: 9,
  });
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null); // State for image
  const [loading, setLoading] = useState(false); // State for loader

  const toolsOptions = [
    "Zoominfo", "Data Entry", "Web Research", "LinkedIn", "Spreadsheet",
    "Leads Generation", "Salesforce Management", "Yellowpages", "Yelp", "B2B",
    "Data.com", "Excel", "Podio", "Data Mining",
  ];

  const handleToolSelection = (tool) => {
    const toolsArray = formData.tools ? formData.tools.split(",") : [];
    if (toolsArray.includes(tool)) {
      const updatedTools = toolsArray.filter((t) => t.trim() !== tool.trim());
      setFormData({ ...formData, tools: updatedTools.join(",") });
    } else {
      setFormData({ ...formData, tools: [...toolsArray, tool].join(",") });
    }
  };

  const handleToolInput = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, tools: value });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: fieldValue });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the selected image
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    try {
      let imageUrl = "";

      if (image) {
        // Step 1: Upload image to Cloudinary
        const cloudinaryData = new FormData();
        cloudinaryData.append("file", image);
        cloudinaryData.append("upload_preset", "l4c_preset"); // Replace with your upload preset
        cloudinaryData.append("cloud_name", "dlimqirys"); // Replace with your cloud name

        const cloudinaryResponse = await axios.post(
          "https://api.cloudinary.com/v1_1/dlimqirys/image/upload",
          cloudinaryData
        );
        imageUrl = cloudinaryResponse.data.secure_url; // Cloudinary's image URL
      }
      console.log(imageUrl)
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });

      // Correcting the backend field name
      data.append("details", description); 
      if (imageUrl) {
        data.append("imageUrl", imageUrl); // Include Cloudinary URL
      }
      if (image) {
        data.append("image", image); // Append the image to FormData
      }

      await axios.post("https://www.leads4contact.com/backendnode/project", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Project added successfully!");
      setFormData({
        title: "",
        tools: "",
        active: true,
        status: "",
        projectRanking: 0,
        share: false,
        endDate: "1900-01-01",
        clientReview: "",
        clientName: "",
        sortOrder: 0,
      });
      setDescription("");
      setImage(null);
    } catch (error) {
      console.error("Error:", error);
      alert("Error adding project!");
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Add New Project</h2>
      <form onSubmit={handleSubmit} className="max-w-full bg-white p-6 shadow-md rounded ">
        {loading && (
          <div className="flex justify-center items-center mb-4">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div>
          </div>
        )}
        {/* Form Fields */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Title</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Tools</label>
          <div className="grid grid-cols-3 gap-4 mb-2">
            {toolsOptions.map((tool) => (
              <div key={tool} className="flex items-center">
                <input
                  type="checkbox"
                  id={tool}
                  checked={formData.tools.split(",").includes(tool)}
                  onChange={() => handleToolSelection(tool)}
                  className="mr-2"
                />
                <label htmlFor={tool} className="text-gray-700">{tool}</label>
              </div>
            ))}
          </div>
          <input
            type="text"
            name="tools"
            value={formData.tools}
            onChange={handleToolInput}
            className="w-full border rounded px-3 py-2"
            placeholder="Add tools, separated by commas"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Description</label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            className="bg-white"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Project Ranking</label>
          <input
            type="number"
            name="projectRanking"
            value={formData.projectRanking}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            min="1" // Minimum value
            max="5" // Maximum value
            required // Ensures the field is not empty
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Share</label>
          <input
            type="checkbox"
            name="share"
            checked={formData.share}
            onChange={handleChange}
            className="mr-2"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Client Review</label>
          <textarea
            name="clientReview"
            value={formData.clientReview}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            rows="2"
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Client Name</label>
          <input
            type="text"
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Sort Order</label>
          <input
            type="number"
            name="sortOrder"
            value={formData.sortOrder}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
            {/* Status */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          >
            <option value="Continue">Continue</option>
            <option value="Ended">Ended</option>
          </select>
        </div>

        {/* End Date */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">End Date</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
            disabled={formData.status !== "Ended"}
            required={formData.status === "Ended"}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Active</label>
          <input
            type="checkbox"
            name="active"
            checked={formData.active}
            onChange={handleChange}
            className="mr-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Upload Image</label>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleImageChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          disabled={loading} // Disable button while loading
        >
          {loading ? "Adding Project..." : "Add Project"}
        </button>
      </form>
    </div>
  );
};

export default AddProject;
